import * as Yup from 'yup';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// tslint:disable-next-line:typedef
export const useForgotPassValidationSchema = () => {
  const { t } = useTranslation();
  // tslint:disable-next-line:typedef
  return useMemo(() => {
    return Yup.object().shape({
      password: Yup.string().required(t('validations.required')).min(8, t('validations.password')),
      re_password: Yup.string()
        .required(t('validations.required'))
        .oneOf([Yup.ref('password')], t('validations.confirmPassword')),
      code: Yup.string().required('placeholder').min(6, 'placeholder'),
    });
  }, [t]);
};
