import * as Yup from 'yup';

import { emailRegex, nameRegex, phoneRegex } from '../../../../../utils/const';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useSignupValidationSchema = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return Yup.object().shape({
      phone: Yup.string()
        .required(t('validations.required'))
        .min(7, t('verifyPhonePage.errors.incorrectData'))
        .max(10, t('verifyPhonePage.errors.incorrectData'))
        .matches(phoneRegex, t('validations.phone_number')),
      name: Yup.string()
        .required(t('validations.required'))
        .min(2, t('validations.min'))
        .matches(nameRegex, t('validations.name')),
      password: Yup.string().required(t('validations.required')).min(8, t('validations.password')),
      re_password: Yup.string()
        .required(t('validations.required'))
        .oneOf([Yup.ref('password')], t('validations.confirmPassword')),
      email: Yup.string().email(t('validations.email')).matches(emailRegex, t('validations.email')),
      code: Yup.string().required(t('validations.code')),
      recaptcha_token: Yup.string().required(t('validations.required')),
      terms: Yup.boolean().oneOf([true], t('validations.required')),
    });
  }, [t]);
};
