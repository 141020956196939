export const uk = {
  translation: {
    validations: {
      required: "Обов'язкове поле",
      email: 'Невірна адреса електронної пошти',
      shouldBeChecked: 'Підтвердіть умови',
      min: 'Занадто короткий',
      max: 'Занадто довга',
      onlyLetters: 'Має містити лише літери',
      name: 'Має містити лише літери та символи «-», « »',
      phone_number: 'Повинен містити тільки цифри',
      onlyDigitsAndLetters: 'Має містити лише цифри',
      password: 'Має бути не меньше 8 символів',
      code: "Обов'язково оберить країну",
      confirmPassword: 'Підтвердження має збігатися з паролем',
      color: `Ви ввели заборонені символи`,
      noUnicode: `Ви ввели заборонені символи`,
      special_signs: `Ви ввели заборонені символи`,
      chip: 'Довжина коду не може перевищувати 30 символів',
      emptyField: 'Поле не може бути порожнім',
      id_chip_stigma: 'Має містити лише літери, цифри та символи «-», « »',
      prohibitedCharacters: 'Має містити лише літери, цифри та символи «-», « »',
    },
    Phone: {
      countryTitle: 'Kраїна',
      searchPlaceholder: 'Пошук...',
      phonePlaceholder: 'Номер телефону',
    },
    startPage: {
      login: 'Увійти',
      signup: 'Зареєструватися',
      or: 'АБО',
    },
    loginPage: {
      title: 'Увійти',
      keepLoggedin: 'Залишити мене в системі',
      logIn: 'Увійти',
      forgot: 'Забули свій пароль?',
      placeholders: {
        login: 'Номер телефону',
        password: 'Пароль',
      },
      errors: {
        incorrectData: 'Невірний номер телефону або пароль!',
        trouble: 'Сталась помилка. Спробуйте ще раз пізніше.',
      },
      back: 'Назад',
    },
    forgotPage: {
      title: 'Забули пароль',
      description: 'На вказаний номер буде відправлено СМС з кодом активації',
      placeholders: {
        phone: 'Номер телефону',
      },
      errors: {
        incorrectData: 'Немає жодного користувача з цим номером!',
        trouble: 'Пароль змінюється в Facebook або Google аккаунті',
      },
      passwordWasChanged: 'Ви успішно змінили пароль!',
      sendCode: 'Відправити код',
      back: 'Назад',
    },
    resetPassPage: {
      title: 'Скинути пароль',
      description: 'Введіть новий пароль та КОД підтвердження',
      subtitle: 'Будь ласка, введіть код підтвердження, надісланий на номер ',
      verify: 'Підтвердити',
      resend: 'ВІДПРАВИТИ КОД ПОВТОРНО',
      resendMessage: 'Не отримали код?',
      error: 'Код недійсний',
      placeholders: {
        phone: 'Номер телефону',
        password: 'Новий пароль',
        re_password: 'Підтвердіть пароль',
      },
      reset: 'Скинути пароль',
      back: 'Назад',
    },
    signupPage: {
      title: 'Зареєструватися',
      placeholders: {
        phoneNumber: 'Номер телефону',
        name: "Ім'я",
        password: 'Пароль',
        re_password: 'Підтвердіть пароль',
        email: 'Електронна пошта',
      },
      noCountriesFound: 'Країни не знайдено!',
      terms: {
        text: 'Я прочитав',
        link: 'Політику конфіденційності',
        title: 'Політика конфіденційності',
      },
      register: 'Зареєструватися',
      back: 'Назад',
      errors: {
        userExists: 'Користувач із поточним номером телефону вже існує',
        missingRecaptcha: 'Необхідно підтвердити рекаптчу',
        trouble: 'Сталась помилка. Спробуйте ще раз пізніше.',
      },
    },
    verifyPhonePage: {
      title: 'Будь ласка, введіть свій номер телефону',
      description: 'На вказаний номер буде відправлено СМС з кодом активації',
      next: 'Відправити код',
      back: 'Назад',
      placeholder: 'Номер телефону',
      errors: {
        incorrectData: 'Невірний номер телефону!',
        trouble: 'Сталась помилка. Спробуйте ще раз пізніше.',
        socialNumberExist: 'Цей номер вже використовується!',
      },
    },
    verifyPhoneCode: {
      back: 'Назад',
      title: 'Код підтвердження',
      subtitle: 'Будь ласка, введіть код підтвердження, надісланий на номер ',
      verify: 'Перевірити',
      resendMessage: 'Не отримали код?',
      resend: 'НАДІСЛАТИ КОД ПОВТОРНО',
      error: 'Код недійсний',
      codeIsIncomplete: 'Код введено не повністю',
    },
    shared: {
      footerMenu: {
        calendar: 'Календар',
        map: 'Карта',
        chat: 'Чат',
        contacts: 'Контакти',
      },
      addCard: {
        title: 'Додати новий',
      },
      spinnerText: 'завантаження...',
    },
    dashboard: {
      pets: 'Домашні тварини',
      owner: 'Власник',
      deletePetNotificationText: 'Ви дійсно хочете видалити цього улюбленця?',
      limitForCreatingPetsNotificationTitle: 'Тваринка',
      limitForCreatingPetsNotification: 'Ви досягли ліміту додавання улюбленців!',
      bellNotificationTitle: 'Подiї',
      bellNotificationText: 'У вас більше немає подій сьогодні',
    },
    sidebar: {
      pet_name: 'Кличка улюбленця',
      edit: 'Редагувати профіль',
      language: 'Мова',
      confidentiality: 'Конфіденційність',
      confItem: 'Всі',
      theme: 'Тема',
      notifications: 'Сповіщення',
      map: 'Карта',
      information: 'Інформація',
      support: 'Допомога',
      logout: 'Вийти',
      getPremium: 'Отримати преміум',
    },
    ProfilePage: {
      title: 'Профіль',
      edit: 'Редагувати',
      ukraine: 'Україна',
      placeholders: {
        first_name: 'Ім`я',
        last_name: 'Прiзвище',
        birthday: 'Дата народження',
        email: 'example@mail.com',
        phone_number: 'Номер телефону',
        male: 'Чоловік',
        female: 'Жінка',
        country: 'Країна',
        city: 'Місто',
      },
      popUp: {
        deleteOrHideTitle: 'Ви хочете повністю видалити Ваш акаунт?',
        deleteTitle:
          'Ви точно хочете видалити свій профіль? Ця дія є не зворотньою, усі Ваші фото, тваринки, події та профіль буде видалений на завжди!!! Ви впевнені?',
        hideTitle: 'Ви хочете приховати юзера (Ваші розшарені фото на стрічці не будуть відображатись)?',
        unHideTitle: 'Ви хочете знову показувати свій профіль?',
        cancelButton: 'Відмінити',
        hideButton: 'Приховати',
        unHideButton: 'Показати',
        deleteButton: 'Видалити',
      },
      notifications: {
        unHide: 'Ваші розшарені фото знову показуються на стрічці!',
        hide: 'Ваш акаунт був прихований, всі розшарені фото не будуть показуватись!',
        delete: 'Ваш акаунт був повністю успішно видалений!',
      },
    },

    EditPage: {
      title: 'Редагування профілю',
      Done: 'Зберегти',
      errors: {
        avatar: 'Невалідний формат зображення!',
        error: 'Деякі дані недійсні',
        trouble: 'Сталась помилка. Спробуйте ще раз пізніше.',
      },
      notifications: {
        successProfileUpdate: 'Профіль користувача оновлено успішно!',
      },
      placeholders: {
        first_name: "Ім'я",
        last_name: 'Прізвище',
        birthday: 'Дата народження',
        phone_number: 'Номер телефону',
        email: 'example@mail.com',
        country: 'Країна',
        city: 'Місто',
        search: 'Пошук...',
        citySearch: 'Нічого не знайдено',
      },
      ukraine: 'Україна',
      male: 'Чоловік',
      female: 'Жінка',
    },
    EditPetPage: {
      title: 'Редагування профілю',
      Done: 'Done',
      errors: {
        error: 'Some of data is invalid',
      },
      placeholders: {
        kind: 'Kind',
        name: 'Name',
        birthday: 'Date of birth',
        breed: 'Breed',
        gender: 'Sex',
        color: 'Color',
        special_signs: 'Special signs',
        place_of_extension: 'Place of extension',
        temperament: 'Temperament',
      },
      ukraine: 'Ukraine',
      male: 'Male',
      female: 'Female',
    },
    petForm: {
      title: 'Додати улюбленця',
      submitButtonText: 'Зберегти',
      errors: {
        incorrectData: 'Ви ввели невірні дані!',
        wrongTimeTitle: 'Подія',
        wrongTimeSubtitle: 'Ви не можете створити подію у вибраний час!',
        createEventError: 'Нажаль, не вдалося створити подію!',
      },
      placeholders: {
        kind: 'Вид',
        name: 'Кличка',
        birthday: 'День народження',
        breed: 'Порода',
        gender: 'Стать',
        color: 'Колір',
        special_signs: 'Особливі ознаки',
        food: 'Тип їжі',
        sterilization: 'Стерелізація',
        living_place: 'Місце проживання',
        outdoor_schedule: 'Розклад прогулянок',
        animal_id: 'Чіп/Клеймо ID',
        temperament: 'Характер',
      },
      male: 'Чоловіча',
      female: 'Жіноча',
      stigma: 'Клеймо',
      chip: 'Чіп',
      natural: 'Натуралка',
      Feed: 'Корм',
      mixed: 'Змішаний тип',
      add: 'Додати',
      delete: 'Видалити',
      yes: 'Так',
      no: 'Ні',
      not_chosen: 'Не вибрано',
      no_chip: 'Немає',
      limit_notification: 'Досягнутий ліміт кількості вигулів тваринки!',
      max_time_notification: 'Ви не можете добавити вигул тваринки пізніше 23:00 (11:00pm)!',
      notifications: {
        successAddedPet: 'Тваринка успішно додана!',
        successEditPet: 'Профіль тваринки оновлено успішно!',
        petNotFound: 'Дана тваринка не існує, або була видалена!',
      },
    },
    petInfo: {
      registration_date: 'Дата реєстрації',
      stigma: 'Клеймо ID',
      chip: 'Чіп ID',
    },

    LanguagePage: {
      title: 'Мова',
    },
    confidentPage: {
      title: 'Конфіденційність',
      status: 'Онлайн статус',
    },
    helpPage: {
      title: 'Допомога',
      problem: 'Повідомити про проблему',
      center: 'Центр допомоги',
      reviews: 'Відгуки',
    },
    informationPage: {
      title: 'Інформація',
      contacts: 'Контакти',
      about: 'Про',
    },
    notificationsPage: {
      title: 'Сповіщення',
      show: 'Показати сповіщення',
      messReq: 'Запити на повідомлення',
      messages: 'Повідомлення',
      reqJoinGroup: 'Запити на вступ до групи',
    },
    Contacts: {
      title: 'Додати користувача',
      addButton: 'Додати',
      description1: 'Ви впевнені, що хочете видалити ',
      description2: 'цей контакт?',
      sww: 'Щось пішло не так',
      tryAgain: 'Спробуйте знову',
      deleteDescription: 'Видалення успішне',
      placeholders: {
        search: 'Пошук...',
        yesDelete: 'Так, видалити',
        delete: 'Видалити',
        change: 'Змінити',
        cancel: 'Відмінити',
        pending: 'В очікуванні...',
      },
    },
    AddContact: {
      title: 'Додати користувача',
      placeholders: {
        search: 'Пошук...',
        delete: 'Видалити',
        resend: 'Повторити',
      },
    },
    invite: {
      description1: 'Вибачте, ми не знайшли користувачів із цим номером телефону.',
      description2: 'Ви можете запрошувати нових користувачів.',
      inviteButton: 'Запросити',
    },
    selects: {
      kind: {
        crows: 'Ворона',
        owls: 'Сова',
        parrots: 'Папуга',
        canaries: 'Канарка',
        amadins: 'Амадін',
        snakes: 'Змія',
        lizards: 'Ящірка',
        crocodiles: 'Крокодил',
        alligators: 'Алігатор',
        turtles: 'Черепаха',
        squirrels: 'Білка',
        guinea_pigs: 'Морська свинка',
        mice: 'Миша',
        rats: 'Пацюк',
        chinchillas: 'Шиншила',
        hamsters: "Хом'як",
        anteaters: 'Мурахоїд',
        bears: 'Ведмедь',
        lynx: 'Рись',
        cougars: 'Пума',
        panthers: 'Пантера',
        lions: 'Лев',
        tigers: 'Тигр',
        fishes: 'Риба',
        rabbits: 'Кролик',
        insects: 'Комаха',
        amphibians: 'Амфібія',
        birds: 'Птах',
        reptiles: 'Рептилія',
        ferrets: 'Тхір',
        monkeys: 'Мавпа',
        foxes: 'Лисиця',
        pigs: 'Свиня',
        horses: 'Кінь',
        cats: 'Кіт',
        dogs: 'Собака',
      },
      place: {
        apartment: 'Квартира',
        house: 'Будинок',
        other: 'Інше',
      },
      temperament: {
        friendly: 'Дружелюбний',
        unfriendly: 'Недружелюбний',
        middle: 'Нейтральний',
      },
    },
    appErrorNotification: {
      titleUser: 'Профіль користувача',
      tryLater: 'Щось пішло не так, спробуйте, будь-ласка, пізніше!',
      titleBadCredentials: 'Облікові дані',
      badCredentials: 'Невірний номер або пароль!',
      invalidData: 'Невірний номер чи email',
      tooFast: 'Відправити код можна не частіше ніж 1 раз на хвилину, спробуйте пізніше!',
      noTokenTitle: 'Помилка',
      noTokenText: 'Доступ заборонено. Перезавантажте свою сторінку',
      title_429: 'Упс!',
      text_429: 'Ви досягли ліміту в 100 івентів цього року',
      titleEvent: 'Подія',
      textEvent: 'Нажаль, сталась помилка отримання списку подій!',
      eventForm: 'Нажаль, сталась помилка отримання події!',
      tooManyRequests: 'Нажаль, було відправлено забагато запитів на сервер, спробуйте пізніше!',
      noEvents: 'Немає повідомлень!',
    },
    PetProfilePage: {
      title: 'Профіль тварини',
      share: 'Поділитись',
      tabs: {
        gallery: 'Галерея',
        info: 'Інформація',
        medical: 'Ветеринар',
        calendar: 'Календар',
      },
      calendar: {
        addButton: 'Додати подію тваринки',
        addButton2: 'Повернутись до списку',
        allergies: 'Алергiя',
        vaccination: 'Вакцинацiя',
        treatment: 'Лікування від паразитів',
        vet: 'Запис до ветеринара',
        services: 'Додаткові послуги',
        noEvents: 'Цього дня немає запланованих подій',
        loadingEvents: 'Подiї завантажуються...',
        today: 'Сьогодні',
      },
      notifications: {
        titlePet: 'Профіль тваринки',
        invalidDataTitle: 'Неправильна дата або час',
        forbiddenInPast: 'Ви не можете створити подію в минулому!',
        successTitle: 'Успіх!',
        successText: 'Ви успішно створили ',
        successEditText: 'Ви успішно змiнили ',
        deleteEvent: 'Ви дійсно хочете видалити цю подію?',
        successAddedPhoto: 'Ви успішно додали світлину!',
        shareSuccess: 'Вам відправлено код, передайти будь-яким зручним способом його родичу чи другу!',
        wrongCode: 'Ви ввели невірний код з СМС, спробуйте інший!',
        invalidCode: 'Невірно введений код!',
        ownThisPet: 'У вас вже э ця тваринка!',
        expiredCode: 'Ведений код застарілий, спробуйте інший!',
        galleryLimit: 'Досягнутий ліміт 10 світлин на день!',
        eventPhotoLimit: 'Перевищений ліміт світлин для цього івенту!',
        successDeleteEvent: 'Ви успішно видалили подію!',
        wrongImageFormat: 'Ви не можете добавити в галерею цей формат зображення!',
        editEventError: 'Нажаль, сталась помилка при редагуванні події!',
        fileTooLarge: 'Ваше зоброження більше за 10MB! Спробуйте, будь ласка, іншу світлину!',
        eventDeleteError: 'Нажаль, сталась помилка при видаленні події!',
        forbiddenSharePhoto: 'Ви не можете розшарити це фото, бо Ваш профіль прихований!',
      },
      commonEventForm: {
        save: 'Зберегти',
        add: 'Додати ',
        emptyListText: 'Немає жодного',
        time: 'Час',
        date: 'Дата',
        now: 'Зараз',
      },
      allergyForm: {
        periodName: 'Дата появи симптомів: ',
        pageTitle: 'Алергія',
        emptyListMarker: 'Алергій нема!',
        addedText: 'алергію',
        buttonAlt: 'алергія',
        addPageTitle: 'Додати алергію',
        editPageTitle: 'Редагувати алергію',
        allergyTypeTitle: 'Вид алергії',
        allergyTypeOptions: {
          drug: 'Алергія на ліки',
          food: 'Харчова алергія',
          insect: 'Укус комахи',
          other: 'Інше',
        },
        addPageInputFields: {
          drug: 'Назва алергену',
        },
        addPageTextareaFields: {
          notes: 'Нотатки',
        },
      },
      servicesForm: {
        periodName: 'Дата візиту: ',
        pageTitle: 'Додаткові послуги',
        emptyListMarker: 'Додаткові послуги відсутні',
        addedText: 'додаткову послугу',
        buttonAlt: 'Додаткові послуги',
        addPageTitle: 'Додати послугу',
        editPageTitle: 'Редагувати послугу',
        addPageInputFields: {
          name: 'Назва послуги',
          institution: 'Назва установи',
          address: 'Адреса',
        },
        addPageTextareaFields: {
          notes: 'Нотатки',
        },
      },
      vaccinationForm: {
        periodName: 'Дата щеплення: ',
        pageTitle: 'Щеплення',
        emptyListMarker: 'Щеплення відсутні',
        addedText: 'щеплення',
        buttonAlt: 'щеплення',
        addPageTitle: 'Додати щеплення',
        editPageTitle: 'Редагувати щеплення',
        addPageInputFields: {
          name: 'Назва щеплення',
          drug: 'Назва препарату',
          address: 'Адреса',
        },
        addPageTextareaFields: {
          notes: 'Нотатки',
        },
      },
      treatmentForm: {
        periodName: 'Дата лікування: ',
        pageTitle: 'Лікування від паразитів',
        emptyListMarker: 'Жодного запису про лікування від паразитів',
        addedText: 'лікування від паразитів',
        buttonAlt: 'Лікування від паразитів',
        addPageTitle: 'Додати лікування',
        editPageTitle: 'Редагувати лікування',
        addPageInputFields: {
          reason: 'Причина лікування',
          drug: 'Назва препарату',
          address: 'Адреса',
        },
        addPageTextareaFields: {
          notes: 'Нотатки',
        },
      },
      veterinarianForm: {
        periodName: 'Дата прийому: ',
        pageTitle: 'Запис до ветеринара',
        emptyListMarker: 'Жодного запису на прийом до ветеринара',
        addedText: 'запис до ветеринара',
        buttonAlt: 'ветеринарні послуги',
        addPageTitle: 'Додати запис до лікаря',
        editPageTitle: 'Редагувати зустріч',
        addPageInputFields: {
          name: 'Назва ветклініки',
          address: 'Адреса клініки',
          procedure: 'Назва процедури',
        },
        addPageTextareaFields: {
          notes: 'Нотатки',
        },
      },
    },
    ViewEventPage: {
      edit: 'Редагувати',
      delete: 'Видалити',
      date: 'Дата',
    },
    Notification: {
      geolocation:
        'Для вірного відображення місцевого часу увімкніть, будь-ласка, геолокацію у налаштуваннях браузера!',
    },
    PopUp: {
      cancel: 'Відмінити',
      continue: 'Продовжити',
      yes: 'Так',
      no: 'Ні',
      loosingData: 'Ви можете втратити дані на цій сторінці, якщо не збережете їх!',
      sharePet: "Чи хочете розшарити цю тваринку Вашому родичу чи другу? Ваша тваринка з'явиться у нього в профілі!",
      sharePhotoAfterUpload:
        'Це фото може бути розшарене у стрічку фото, щоб Вашого улюбленця побачили інші користувачі! Розшарити це фото зараз?',
      deletePhoto: 'Ви впевнені, що хочете видалити цю фотографію?',
      editCommonEvent: 'Ви впевнені, що хочете змінити івент, що нажить ',
    },
    AddPetPage: {
      title: 'Додати улюбленця',
      subtitle: 'Способи додавання тваринки',
      button: {
        send: 'Надіслати',
        next: 'Далі',
      },
      radioButton: {
        code: 'За кодом чужу тваринку',
        form: 'Заповнити форму',
      },
    },
    GlobalCalendar: {
      title: 'Календар подій',
      selectPlaceholder: 'Ваша тварина',
      allPetsSelect: 'Усi тварини',
      noPetsText: 'Щоб користуватися календарем, ви повинні мати принаймні одну тварину!',
    },
    CheckboxSelect: {
      generalBtn: 'Обрати все',
      generalClearBtn: 'Очистити все',
      acceptBtn: 'Ок',
    },
    NoInternet: {
      lowConnectionTitle: 'Низька якість мережі',
      lowConnectionText: 'Спробуйте підключитися з більшою якістю передачі для належної роботи додатку',
      retry: 'Оновити',
    },
    ErrorPage: {
      message: {
        err401: 'Помилка 401: Несанкціонований доступ. Ваші дані для входу недійсні або застарілі.',
        err403:
          "Помилка 403: Доступ заборонено. Ви не маєте достатніх прав для доступу до цієї сторінки або ресурсу. Будь ласка, зв'яжіться з адміністратором для отримання додаткової інформації.",
        err404:
          'Помилка 404: Сторінку не знайдено. Вибачте, але запитувана вами сторінка або ресурс не існує на нашому сервері. Будь ласка, перевірте правильність введеного URL або спробуйте знову пізніше.',
        err500: 'Помилка 500: Внутрішня помилка сервера. Вибачте за тимчасові незручності.',
        err503:
          'Помилка 503: Сервіс недоступний. Вибачте за тимчасові незручності, наш сервер тимчасово недоступний через перевантаження або технічні проблеми. Будь ласка, спробуйте оновити сторінку або повернутися пізніше.',
        default: 'Щось пішло не так',
      },
      buttons: {
        logIn: 'Увійти',
        dashboard: 'До дашборду',
        back: 'Повернутись назад',
        retry: 'Оновити',
        tryAgain: 'Спробувати ще',
      },
    },
    Support: {
      title: 'Підтримка',
      help: 'Чим можемо Вам допомогти?',
      complain: 'Що саме вам не подобається в цьому фото?',
      RequestForm: {
        title: 'Заголовок',
        body: 'Повідомлення',
        email: 'Ваш email',
        submit: 'Надіслати звернення',
        successTitle: 'Запит відправлено',
        successMessage: 'Ваш запит був відправлений, ми розглянемо його і надамо відповідь Вам найближчим часом!',
      },
    },
    Feed: {
      noPhoto: {
        title: 'Вибачте, спільних світлин немає!',
        subtitle: 'Будь ласка, додайте сюди світлини з галереї ваших улюбленців!',
      },
      sharePhoto: {
        successShareText: 'Ви успішно поділились світлиною!',
        successUnshareText: 'Ви успішно прибрали світлину зі стрічки!',
        errorTitle: 'Помилка',
        errorShareText: 'Нажаль, сталась помилка при спробі поділитись світлиною!',
        errorUnshareText: 'Нажаль, сталась помилка при спробі прибрати світлину зі стрічки!',
        supportSuccess:
          'Ви пожалілись на цю світлину! Наші модератори переглянуть Вашу сукаргу та найближчим часом дадуть відповідь по пошті!',
        supportError: 'Нажаль не вдалось відправити вашу скаргу на цю світлину! Спробуйте пізніше!',
        popupShareText: 'Ви хочете розшарити цю світлину на стрічку?',
        popupUnshareText: 'Ви хочете видалити цю світлину зі своєї стрічки?',
      },
      complainPhoto: {
        popUpText: 'Ви хочете поскаржитися на цю світлину?',
        supportTitle: 'Ви жалієтесь на цю світлину',
        supportBody: 'Посилання на цю світлину:',
        errorTitle: 'Помилка',
        supportSuccess:
          'Ви пожалілись на цю світлину! Наші модератори переглянуть Вашу сукаргу та найближчим часом дадуть відповідь по пошті!',
        supportError: 'Нажаль не вдалось відправити вашу скаргу на цю світлину! Спробуйте пізніше!',
      },
      hidePhoto: {
        popUpText: 'Забанити цю світлину?',
        hideSuccess: 'Ви приховали цю світлину!',
        hideError: 'Нажаль не вдалось приховати цю світлину!',
      },
      notifications: {
        titleFeed: 'Стрічка',
      },
    },
  },
};
